<template>
    <modal ref="verTenderos" :titulo="titulo" tamano="modal-lg" no-aceptar no-cancelar>
        <div class="px-4">
            <div v-for="(data, idx) in tipo==1?tenderosActivos: tenderosMorosos
			" :key="idx" class="row mx-0 align-items-center cr-pointer mb-1" @click="irATendero(data)">
                <img class="img-logo border bg-white" :src="data.logo_mini" alt="" />
                <div class="col-5 text-general f-600">
                    <p>{{ data.nombre }}</p>
                </div>
                <div class="col-3 text-general2 text-center">
                    {{ separadorNumero(data.total) }}
                </div>
                <div v-if="tipo==2" class="col-3 text-center text-general2 f-500">
                    {{ data.fecha_menor_cuota }}
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    data(){
        return {
            titulo: '',
            tipo: 0
        }
    },
    computed:{
        ...mapGetters({
            tenderosMorosos: 'almacen/credito/tenderosMorosos',
            tenderosActivos: 'almacen/credito/tenderosActivos',
        })
    },
    methods: {
        toggle(t){
            this.tipo = t
            if (t==1){
                this.titulo = `${this.$config.cliente} con créditos activos`
            }else{
                this.titulo = `${this.$config.cliente} en mora`
            }
            this.$refs.verTenderos.toggle()
        },
        irATendero({ id_tienda }){
            // this.$refs.verTenderos.toggle()

            // this.$router.push({ name: 'admin.tiendas.produccion.creditos.tenderoVer', params: { id_tienda } })
        }
    }
}
</script>

<style lang="css" scoped>
.img-logo{
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
}
</style>
